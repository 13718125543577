<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      :disable-sort="disableSort"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">{{ item.text }}</td>
      </template>
      <template v-slot:item.status="{ item }">
        <v-checkbox
          :input-value="item.status === 'processed'"
          @change="toggleStatus(item)"
          color="secondary"
        ></v-checkbox>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ getFormattedDate(item.created_at) }}
      </template>
      <template v-slot:item.screenshot="{ item }">
        <a
          v-if="item.screenshot"
          download
          title="ImageName"
          :href="item.screenshot"
          target="_blank"
        >
          изображение
        </a>
        <span v-if="!item.screenshot">—</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="showDeleteModal(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <SimpleYesNoModal
      :show="isShownDeleteModal"
      :question="'Вы хотите удалить отчет о нарушении?'"
      @yes="deleteItem(selectedItemId)"
      @no="isShownDeleteModal = false"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
import api from "../../../api";
import { mapActions } from "vuex";
import SimpleYesNoModal from "../../components/SimpleYesNoModal.vue";

export default {
  components: {
    SimpleYesNoModal,
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "статус",
          align: "start",
          value: "status",
          width: 90,
        },
        {
          text: "id урока",
          align: "start",
          value: "entity_id",
          width: 100,
        },
        {
          text: "тип урока",
          align: "start",
          value: "entity_type",
          width: 150,
        },
        {
          text: "id отправителя",
          align: "start",
          value: "user.id",
          width: 140,
        },
        {
          text: "имя отправителя",
          align: "start",
          value: "user.name",
          width: 140,
        },
        {
          text: "email отправителя",
          align: "start",
          value: "user.email",
          width: 140,
        },
        {
          text: "id нарушителя",
          align: "start",
          value: "violator.id",
          width: 140,
        },
        {
          text: "имя нарушителя",
          align: "start",
          value: "violator.name",
          width: 140,
        },
        {
          text: "email нарушителя",
          align: "start",
          value: "violator.email",
          width: 140,
        },
        {
          text: "нарушение",
          align: "start",
          value: "violation_type",
          width: 240,
        },
        {
          text: "изображение",
          align: "center",
          value: "screenshot",
          width: 140,
        },
        // {
        //   text: "описание",
        //   align: "start",
        //   value: "text",
        //   width: 140,
        // },
        {
          text: "дата создания",
          align: "start",
          value: "created_at",
          width: 160,
        },
        {
          text: "действия",
          align: "end",
          width: 120,
          value: "actions",
        },
      ],
      items: [],
      optionsTable: {},
      total: 0,
      disableSort: true,
      loading: true,
      isShownDeleteModal: false,
      selectedItemId: null,
    };
  },
  watch: {
    optionsTable: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    items: {
      handler() {
        this.setupReportsPageCounter();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      setupReportsPageCounter: "setupReportsPageCounter",
    }),
    async loadData() {
      const props = {};
      if (this.optionsTable.page) {
        props.page = this.optionsTable.page;
      }
      if (this.optionsTable.itemsPerPage) {
        props.itemsPerPage = this.optionsTable.itemsPerPage;
      }

      try {
        const response = await api.reports.fetchViolations(props);
        this.items = response.data.items;
        this.total = response.data.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async toggleStatus(item) {
      let newStatus;
      switch (item.status) {
        case "unprocessed": {
          newStatus = 1;
          break;
        }
        case "processed": {
          newStatus = 0;
          break;
        }
      }
      try {
        const response = await api.reports.changeViolationStatus(item.id, {
          status: newStatus,
        });
        if (response.data.status) {
          this.$store.dispatch("showSnackbar", {
            msg: `Статус изменен`,
            color: "success",
            show: true,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadData();
      }
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    async deleteItem(itemId) {
      try {
        const response = await api.reports.deleteViolation(itemId);
        if (response.status === 204) {
          this.$store.dispatch("showSnackbar", {
            msg: `Запись удалена`,
            color: "success",
            show: true,
          });
        }
        this.isShownDeleteModal = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadData();
      }
    },
    showDeleteModal(itemId) {
      this.selectedItemId = itemId;
      this.isShownDeleteModal = true;
    },
  },
};
</script>
