<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <template>
      <v-card class="pa-1">
        <v-card-title
          class="modal-title text-h5 text-center justify-center flex-column mb-5"
        >
          {{ question }}
        </v-card-title>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="accent" outlined @click="$emit('no')">Нет</v-btn>
          <v-btn color="accent" @click="$emit('yes')">Да</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "SimpleYesNoModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
};
</script>

<style scoped lang="scss">
.modal-title {
  word-break: break-word;
}
</style>
