<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab>
          <div class="d-flex justify-center">
            нарушения
            <div
              v-if="reportsPageCounter && reportsPageCounter.violations"
              class="ml-2 mr-4"
            >
              <v-badge
                color="accent"
                :content="reportsPageCounter.violations"
              />
            </div>
          </div>
        </v-tab>
        <v-tab>
          ошибки
          <div
            v-if="reportsPageCounter && reportsPageCounter.reports"
            class="ml-2 mr-4"
          >
            <v-badge color="accent" :content="reportsPageCounter.reports" />
          </div>
        </v-tab>
        <v-tab>
          отзывы
          <div
            v-if="reportsPageCounter && reportsPageCounter.feedback"
            class="ml-2 mr-4"
          >
            <v-badge color="accent" :content="reportsPageCounter.feedback" />
          </div>
        </v-tab>
        <v-tab-item>
          <violations-table />
        </v-tab-item>
        <v-tab-item>
          <errors-table />
        </v-tab-item>
        <v-tab-item>
          <feedbacks-table />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ViolationsTable from "@/components/Reports/ViolationsTable";
import ErrorsTable from "@/components/Reports/ErrorsTable";
import FeedbacksTable from "@/components/Reports/FeedbacksTable";
import { mapGetters } from "vuex";

export default {
  components: {
    ViolationsTable,
    ErrorsTable,
    FeedbacksTable,
  },
  computed: {
    ...mapGetters({
      reportsPageCounter: "reportsPageCounter",
    }),
  },
};
</script>
