<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      :disable-sort="disableSort"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">{{ item.text }}</td>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ getFormattedDate(item.created_at) }}
      </template>
      <template v-slot:item.status="{ item }">
        <td :style="{ color: getStatusDataById(item.status, 'color') }">
          {{ getStatusDataById(item.status, "name") }}
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="showDeleteModal(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-if="editItemData"
      v-model="isShownEditModal"
      scrollable
      max-width="500px"
      @click:outside="closeModal"
    >
      <v-card>
        <v-card-title class="mb-5 align-start">
          <span class="text-h5 mr-2">Редактирование отзыва пользователя</span>
          <div class="text-h5 accent--text">
            {{ editItemData.user.full_name }}
          </div>
        </v-card-title>

        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" class="py-0">
              <v-textarea
                outlined
                name="input-7-4"
                label="Отзыв"
                v-model.trim="editItemData.text"
                dense
              ></v-textarea>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-select
                v-model="editItemData.grade"
                :items="[1, 2, 3, 4, 5]"
                item-text="name"
                label="Оценка"
                return-object
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="newSelectedStatus"
                :items="feedbacksStatuses"
                item-text="name"
                label="Статус"
                return-object
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="selectedLanguages"
                :items="languagesList.map((l) => l.iso)"
                item-text="name"
                label="Отображать на странице с языком"
                return-object
                multiple
                outlined
                dense
                class="lang-select"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title @click="selectAllLanguages">
                        Выбрать все
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:append-outer>
                  <v-btn icon @click="clearLanguageSelection">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="accent" outlined @click="closeModal">Отмена</v-btn>
          <v-btn color="accent" @click="updateFeedbackData">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SimpleYesNoModal
      :show="isShownDeleteModal"
      :question="'Вы хотите удалить отзыв?'"
      @yes="deleteItem(selectedItemId)"
      @no="isShownDeleteModal = false"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
import api from "../../../api";
import { mapActions, mapGetters } from "vuex";
import SimpleYesNoModal from "../../components/SimpleYesNoModal.vue";

export default {
  components: {
    SimpleYesNoModal,
  },
  data() {
    return {
      feedbacksStatuses: [
        {
          id: 1,
          name: "new",
          color: "#fcc168",
        },
        {
          id: 2,
          name: "accepted",
          color: "#59d56c",
        },
        {
          id: 3,
          name: "declined",
          color: "#fc6868",
        },
        {
          id: 4,
          name: "published",
          color: "#68adfc",
        },
      ],
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "id отправителя",
          align: "start",
          value: "user.id",
          width: 120,
        },
        {
          text: "имя отправителя",
          align: "start",
          value: "user.full_name",
          width: 140,
        },
        {
          text: "email отправителя",
          align: "start",
          value: "user.email",
          width: 160,
        },
        {
          text: "оценка",
          align: "start",
          value: "grade",
          width: 80,
        },
        {
          text: "статус",
          align: "start",
          value: "status",
          width: 100,
        },
        {
          text: "дата создания",
          align: "start",
          value: "created_at",
          width: 150,
        },
        {
          text: "действия",
          align: "end",
          width: 120,
          value: "actions",
        },
      ],
      items: [],
      optionsTable: {},
      total: 0,
      disableSort: true,
      loading: true,
      isShownEditModal: false,
      editItemData: null,
      newSelectedStatus: null,
      selectedLanguages: [],
      isShownDeleteModal: false,
      selectedItemId: null,
    };
  },
  computed: {
    ...mapGetters(["languagesList"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    items: {
      handler() {
        this.setupReportsPageCounter();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      setupReportsPageCounter: "setupReportsPageCounter",
    }),
    selectAllLanguages() {
      this.selectedLanguages = this.languagesList.map((l) => l.iso);
    },
    clearLanguageSelection() {
      this.selectedLanguages = [];
    },
    async loadData() {
      const props = {};
      if (this.optionsTable.page) {
        props.page = this.optionsTable.page;
      }
      if (this.optionsTable.itemsPerPage) {
        props.itemsPerPage = this.optionsTable.itemsPerPage;
      }

      try {
        const response = await api.reports.fetchFeedbacks(props);
        this.items = response.data.items;
        this.total = response.data.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    getStatusDataById(statusId, propName) {
      const statusItem = this.feedbacksStatuses.find((status) => {
        return status.id === statusId;
      });
      return statusItem?.[propName];
    },
    editItem(item) {
      this.editItemData = { ...item };
      this.newSelectedStatus = this.feedbacksStatuses.find(
        (status) => status.id === this.editItemData.status
      );
      this.isShownEditModal = true;
      this.selectedLanguages = item.visibility_on_lang
        ? item.visibility_on_lang.length
          ? item.visibility_on_lang
          : []
        : this.languagesList.map((l) => l.iso);
    },
    closeModal() {
      this.editItemData = null;
      this.newSelectedStatus = null;
      this.selectedLanguages = [];
      this.isShownEditModal = false;
    },
    async updateFeedbackData() {
      if (
        !this.editItemData?.text ||
        !this.editItemData?.grade ||
        !this.newSelectedStatus
      ) {
        return;
      }

      const data = {
        text: this.editItemData.text,
        grade: this.editItemData.grade,
        status: this.newSelectedStatus.id,
        visibility_on_lang: this.selectedLanguages,
      };

      try {
        await api.reports.updateFeedback(this.editItemData.id, data);
        await this.loadData();
        this.closeModal();
        this.$store.dispatch("showSnackbar", {
          msg: `Запись сохранена`,
          color: "success",
          show: true,
        });
      } catch (err) {
        this.$store.dispatch("showSnackbar", {
          msg: `Ошибка при сохранении`,
          color: "error",
          show: true,
        });
        console.error(err);
      }
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    async deleteItem(itemId) {
      try {
        const response = await api.reports.deleteFeedback(itemId);
        if (response.status === 204) {
          this.$store.dispatch("showSnackbar", {
            msg: `Запись удалена`,
            color: "success",
            show: true,
          });
        }
        this.isShownDeleteModal = false;
      } catch (e) {
        console.error(e);
      } finally {
        await this.loadData();
      }
    },
    showDeleteModal(itemId) {
      this.selectedItemId = itemId;
      this.isShownDeleteModal = true;
    },
  },
};
</script>

<style scoped>
.lang-select ::v-deep .v-input__append-outer {
  margin-top: 3px !important;
}
</style>
