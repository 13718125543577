var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsTable,"server-items-length":_vm.total,"loading":_vm.loading,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","disable-sort":_vm.disableSort,"footer-props":{ itemsPerPageOptions: [10, 15, 20, 50, 100] }},on:{"update:options":function($event){_vm.optionsTable=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.text))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":item.status === 'processed',"color":"secondary"},on:{"change":function($event){return _vm.toggleStatus(item)}}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created_at))+" ")]}},{key:"item.screenshot",fn:function(ref){
var item = ref.item;
return [(item.screenshot)?_c('a',{attrs:{"download":"","title":"ImageName","href":item.screenshot,"target":"_blank"}},[_vm._v(" изображение ")]):_vm._e(),(!item.screenshot)?_c('span',[_vm._v("—")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showDeleteModal(item.id)}}},[_vm._v("mdi-delete")])]}}])}),_c('SimpleYesNoModal',{attrs:{"show":_vm.isShownDeleteModal,"question":'Вы хотите удалить отчет о нарушении?'},on:{"yes":function($event){return _vm.deleteItem(_vm.selectedItemId)},"no":function($event){_vm.isShownDeleteModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }